@import '../_variables.scss';

.project-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;

  @media #{$layout-notebook} {
    width: 100vw;
    justify-content: left;
    flex: 1 0 calc(25% - 20px);
  }
}

.project-card {
  flex-basis: calc(50% - 10px);
  width: 400px;
  margin: 10px;

  transition: all 3s ease;
  @media #{$layout-laptop} {
  }
  @media #{$layout-notebook} {
    justify-content: center;
    flex: 1 0 calc(33.33% - 20px);
  }
  @media #{$layout-tablet} {
    flex-basis: calc(50%);
  }
  @media #{$layout-mobile-lg} {
  }
  @media #{$layout-mobile-sm} {
  }
  @media #{$layout-mobile-xs} {
    width: 350px;
  }
  display: flex;
  flex-direction: column;
  height: 450px;
  border: 1px solid lighten($color-border, 1);
  background-color: lighten($color-bg-body, 5);
  color: $color-body;
  padding: 20px;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.25),
    0 4px 8px rgba(0, 0, 0, 0.28),
    0 8px 16px rgba(0, 0, 0, 0.29);

  /* Smooth transition on hover */
  transition: box-shadow 0.6s linear;
  transition: border 0.3s ease-in-out;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;


  // ----------
  &:hover {
    border: 1px solid lighten($color-border, 8);
    box-shadow:
      0 4px 8px rgba(0, 0, 0, 0.35),
      0 8px 16px rgba(0, 0, 0, 0.38),
      0 16px 32px rgba(0, 0, 0, .31);
  }

  .icons {
    display: flex;
    justify-content: space-around;
    margin: 10px;
  }

  // @media (max-width: 720px) {
  //   // width: 300px;
  //   height: 350px;
  //   justify-content: space-between;
  // }
  // @media (max-width: 480px){
  //   // width: 340px;
  //   height: 350px;
  // }
}


.project-card-body {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none;
  text-align: left;
  padding: 0 8px;
  border-left: 1px solid lighten($color-body, 5);
  border-radius: 15px 0 0 15px;
  padding-top: 10px;

  &, .project-card-desc {
    overflow-y: auto;

    // For WebKit browsers
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: lighten($color-bg-body, 5);
    }

    &::-webkit-scrollbar-thumb {
      background-color: lighten($color-bg-body, 5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-body;
    }

    // For Firefox
    scrollbar-width: thin;
    scrollbar-color: lighten($color-heading, 10)  $color-body;
  }
  &:hover {
    transition: border 0.5s linear;
    &::-webkit-scrollbar-thumb {
      border: 1px solid lighten($color-heading, 10);
      background: lighten($color-heading, 10);
    }
  }

  p {
      margin-left: 5px;
  }

  .project-card-bottom {
    margin-top: 200px;
  }
}

.project-card-title {
  margin-left: 40px;
}

.project-btn-container {
  display: flex;
  gap: 10px;
  justify-content: space-around;
}
.project-card-btn {
  background-color: lighten($color-bg-body, 8);
  font-size: 1rem;
  line-height: .6rem;
  cursor: pointer;
  border: 1px solid lighten($color-border, 5);
  border-radius: 4px 4px 0 0;
  width: 85px;

    a {
      color: inherit;  // Ensures the <a> tag doesn't override child colors
      text-decoration: none;

      div {

        &:hover {
          color: lighten($color-heading, 5);
        }
      }
    }

  > div, div {
    position: relative;
    width: 70px;
    right: 8px;

    span:nth-child(0n+1) {
      display: inline-block;
      width: 40px;
      color: $color-heading;
      font-weight: bold;
      position: relative;
      border-top: 1px solid $color-heading;
      border-left: 1px solid $color-heading;
      border-radius: 4px;
      padding-top: 8px;
      opacity: 90%;
      margin-bottom: 2px;
      width: 100%;
    }

    span:nth-child(1n+2) {
      display: inline-block;
      color: $color-body;
      position: relative;
      border-bottom: 1px solid $color-body;
      border-right: 1px solid $color-body;
      opacity: 60%;
      border-radius: 4px;
      padding-bottom: 8px;
      margin-top: 2px;
      width: 100%;
      // left: 6px;
    }
  }

  &:hover {
    background-color: lighten($color-bg-body, 10);
    color: lighten($color-heading, 5);
    transition: $transition;

    > div,  div {
      span:nth-child(0n+1) {
        color: $color-heading;
        font-weight: bold;
      }

      span:nth-child(1n+2) {
        color: $color-body;
      }
    }
  }
}
