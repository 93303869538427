@import '../_variables.scss';

$headerWidth: 50px;
$BlockWidthFull: 1000px;
$BlockWidthTablet: 900px;
$BlockWidthLgCell: 350px;
$BlockWidthMdCell: 300px;

$BlockFontFull: 1rem;
$BlockFontTablet: 0.9rem;
$BlockFontLgCell: 0.8rem;

.main-block{
  // position: absolute;
  height: 90vh;
  width: $BlockWidthFull;
  margin: auto;


  @media #{$layout-desktop-xs} {
    width: 1000px;
  }
  @media #{$layout-laptop} {
    width: 800px;
  }
  @media #{$layout-notebook} {
    width: 700px;
  }
  @media #{$layout-tablet} {
    width: 600px;
  }
  @media #{$layout-mobile-lg} {
    width: 500px;
  }
  @media #{$layout-mobile-sm} {
    width: 450px;
  }
  @media #{$layout-mobile-xs} {
    width: 300px;
  }
  @media (max-width: 300px){
    width: 200px;
  }
}

.code-block {
  // border: 1px solid $color-body;
  background: lighten($color-bg-body, 5);
  height: 75vh;
  width: 100%;
  // max-width: 80vw; // You can set a maximum width if you like
  overflow-x: auto; // Allows horizontal scrolling if the code is too wide

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  border: 1px solid lighten($color-bg-body, 7);
  border: 1px solid lighten($color-border, 1);
  border-radius: 5px;

  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.35),
    0 4px 8px rgba(0, 0, 0, 0.38),
    0 8px 16px rgba(0, 0, 0, 0.31);

  /* Smooth transition on hover */
  transition: box-shadow 0.6s linear;
  transition: border 0.3s ease-in-out;

  &:hover {
    border-top: 2px solid lighten($color-border, 8);
    border-right: 2px solid lighten($color-border, 8);
    border-bottom: 2px solid lighten($color-border, 8);
  }

  animation: pulse 4s infinite; // Fixed this line

  @keyframes pulse {
    0%, 100% { //#E5D18B
      box-shadow: 0 2px 4px  #E5D18B12,
                  0 4px 8px  #E5D18B12,
                  0 8px 16px #E5D18B12;
    }
    25%, 75% { //#E5D18B
      box-shadow: 0 2px 4px  #E5D18B5f
                  0 4px 8px  #E5D18B5f,
                  0 8px 16px #E5D18B5f;
    }
    50% {
      box-shadow: 0 5px 14px  #4F524D18,
                  0 8px 18px  #4F524D18,
                  0 12px 26px #4F524D18;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // Tablet size
  @media #{$layout-tablet} {
    // Adjust styles as needed for tablet size
    margin: 0;
  }

  // Mobile size
  @media (min-width: 410px) {
    // Adjust styles as needed for mobile size

  }
}
.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 69vw;
}

.tabbedFiles-position {
  position: relative;
  // width: $BlockWidthFull;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
}

.tabbedFiles-container{
  position: relative;
  margin: auto;
  background:lighten($color-bg-body, 5);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px 4px 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  border: 2px solid lighten($color-bg-body, 7);
  border: 1px solid lighten($color-border, 1);
  border-bottom: 3px solid lighten($color-bg-body, 5);
  // padding: 5px;
  z-index: 3;
}
.tabbedFiles {
  display: flex;
  gap: 5px;
  flex-direction: row;
}

.tabbedFile {
  position: relative;
  padding: 5px 5px;
  border: 1px solid lighten($color-border, 5);
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    background-color: lighten($color-bg-body, 8);

    .tab-close {
      display: inline;
      color: #FF0000a5;
      // font-size: 1.5rem;
      // font-weight: semi-bold;
      background-color: lighten($color-bg-body, 8);
      border: 1px solid lighten($color-border, 8);
      border-radius: 4px 4px 0 0;
      // // padding: 0 5px;
      // height: 20px;
      // width: 20px;
      width: 25px;
      height: 25px;
      text-align: center;
      vertical-align: middle;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: lighten($color-bg-body, 10);
        border: 1px solid lighten($color-border, 10);
      }

      span {
        position: relative;
        text-aling: center;
        bottom: 3px;
        font-weight: semi-bold;
      }
    }
  }
}
.filename {
  font-size: $BlockFontFull;

  @media (max-width: 450px){
    font-size: $BlockFontLgCell;
  }

}
.tab-close {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  display: none;
  cursor: pointer;
}

.block-container{
  margin: auto;
  margin-right: 50px;
  width: 75vw;
  height: 70vh;

  // Tablet size
  @media #{$layout-tablet} {
  }

  // Mobile size
  @media #{$layout-mobile-lg} {
    margin: 0;
    width: 88vw;

  }
}
.codeBlockHeader {
  display: flex;
  margin: auto;
  height: $headerWidth;
  width: 95%;
  flex-direction: row;
  border: 2px solid lighten($color-bg-body, 7);
  border: 1px solid lighten($color-border, 1);
  border-top: 3px solid lighten($color-bg-body, 5);
  background:lighten($color-bg-body, 5);
  transform: translateY($headerWidth);
  border-radius: 0 0 4px 4px;
  z-index: 3;

  .directory-viewer-btn-container {
    display: flex;
    gap: 10px;
    height: 40px;
    margin-left: 10px;
    padding-right: 10px;
  }

  .directory-viewer-btn {
    z-index: 3;
    color: $color-body;
    padding: 10px 10px;
    border: 1px solid lighten($color-bg-body, 10);
    border-radius: 4px 4px 0 0;
    background: lighten($color-bg-body, 5);
    cursor: pointer;

    h5 {
      position: relative;
      font-size: $BlockFontFull;
      bottom: 8px;

      @media (max-width: 450px){
        font-size: $BlockFontLgCell;
        bottom: 0;
      }
    }

    &:hover {
      background: lighten($color-bg-body, 10);
      border: 1px solid lighten($color-body, 1);

    }

    @media (max-width: 480px) {
      font-size: 1rem;
      padding: 5px 10px;
      top: 5px;
    }
  }

}

.block-title {

  h4 {
    font-size: $BlockFontFull;
    margin-top: 5px;
    margin-left: 5px;
    border-left: 1px solid lighten($color-bg-body, 10);
    padding-left: 10px;
  }


}
.long-title {
  font-size: 2.2rem;


  @media #{$layout-mobile-lg} {
    font-size: 1.9rem;
  }
  @media #{$layout-mobile-sm} {
    font-size: 1.85rem;
  }
  @media #{$layout-mobile-xs} {
    font-size: 1.8rem;
  }
}

.codeblock-padding {
  padding-top: 50px;
}

// Tyler, you were working on getting the styling for your code Block
// to work flawlessly for Desktop, Tablet and Cellphone(esspecially
// cellphone)
.code-container {
  border: 5px solid lighten($color-bg-body, 9);
  border-radius: 5px;
  width: 100%;
  height: 120%;
  margin-top: $headerWidth;
  font-size: $BlockFontFull;
  // width: 100%;
  @media #{$layout-tablet} {
    font-size: $BlockFontTablet;
  }
  // @media #{$layout-mobile-lg} {
  //   font-size: 0.7rem;
  // }

  @media (max-width: 450px){
    font-size: $BlockFontLgCell;
  }
}

