@import '../_variables.scss';


.directory-viewer {
  display: flex;
  position: absolute;
  height: 650px;
  margin-left: 12px;
  width: 0;
  z-index: 3;
  background: red;
}



.directory {
  position: absolute;
  left: -15px;
  top: 100px;
  width: 250px;
  height: 85%;
  padding-top: 5px;
  padding-left: 5px;
  overflow: hidden;
  background: lighten($color-bg-body, 5);
  backdrop-filter: blur(6px) ;
  transition: opacity 0.2s linear;
  z-index: 1;
  overflow-y: auto;
  scrollbar-width: none;
  border-top: 2px solid lighten($color-bg-body, 7);
  border-right: 2px solid lighten($color-bg-body, 9);
  border-bottom: 2px solid lighten($color-bg-body, 7);
  border-left: 2px solid lighten($color-bg-body, 5);

  border-top: 2px solid lighten($color-border, 1);
  border-right: 2px solid lighten($color-border, 1);
  border-bottom: 2px solid lighten($color-border, 1);

  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.05),
    0 4px 8px rgba(0, 0, 0, 0.08),
    0 8px 16px rgba(0, 0, 0, 0.1);

  /* Smooth transition on hover */
  transition: box-shadow 0.6s linear;
  transition: border 0.3s ease-in-out;

  &:hover {
    border-top: 1px solid lighten($color-border, 8);
    border-right: 1px solid lighten($color-border, 8);
    border-bottom: 1px solid lighten($color-border, 8);
    box-shadow:
      0 4px 8px rgba(0, 0, 0, 0.1),
      0 8px 16px rgba(0, 0, 0, 0.12),
      0 16px 32px rgba(0, 0, 0, 0.14);
    }

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* For Chrome, Safari, and newer versions of Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 450px){
    width: 200px;
    height: 70%;
  }
}

.fileName, .directory-btn, .directory-name, .directory-listing {
  color: $color-body;
  font-weight: semi-bold;
  font-size: 1rem;

  cursor: pointer;
  margin-left: 8px;

  &:hover {
    color: $color-heading;
    margin-right: 5px;
  }

  @media (max-width: 820px) {
    // Adjust styles as needed for tablet size
    font-size: 0.8rem;
  }

  // Mobile size
  @media (max-width: 450px) {
    // Adjust styles as needed for mobile size
    font-size: 0.75rem;
  }
}
.directory-btn {
  transition: margin-right 0.3s ease-in;
  font-weight: bold;
  // font-size: 1.25rem;

  &:hover {
    color: $color-heading;
    margin-right: 5px;
  }
}
.fileName {
  font-weight: 500;
  transition: margin-left 0.3s ease-in-out;
  color: lighten($color-theme, 10);

  &:hover {
    margin-left: 12px;
  }

}

.directory-listing {
  margin-left: 8px;
  color: lighten($color-secondary,5);
}
.directory-name {
  color: lighten($color-heading, 10);
  font-weight: bold;

}

.fileName-container {
  display: flex;
  flex-direction: flex-row;
  margin: 5px;

  &:nth-child(2n) {
    background: lighten($color-bg-body, 7);
    transtion: background 0.3s ease;

    &:hover {
      background: lighten($color-bg-body, 8);
    }
  }
  &:nth-child(2n+1){
    background: lighten($color-bg-body, 9);
    transtion: background 0.3s ease;

    &:hover {
      background: lighten($color-bg-body, 8);
    }
  }

  .fileName {
    line-height: 30px;
  }
}

